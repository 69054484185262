.bgimgcontainer {
  position: relative;
  width: 100%;
  height: 440px;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-position 0.1s ease-out;
}

.bgimgcontainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.blackContainer {
  position: absolute;
  top: 70px;
  left: 100px;
  width: 86%;
  height: 300px;
  background: rgba(255, 255, 255, 0.76);
  padding: 10px;
  display: flex;
  justify-content: center;
  gap: 20px;
  z-index: 2;
}

/* Styles for screens 768px and below */
@media screen and (max-width: 768px) {
  .blackContainer {
    width: 90%;
    left: 5%;
    top: 10px;
    height: 500px;
  }

  .bgimgcontainer {
    height: 520px;
  }
}

@media (min-width: 769px) and (max-width: 900px) {
  .blackContainer {

    width: 90%;
    left: 5%;
    top: 10px;
    height: 430px;
  }

  .bgimgcontainer {
    height: 450px;
  }
}

@media (min-width: 900px) and (max-width: 1024px) {
  .blackContainer {
    width: 90%;
    left: 5%;
    top: 10px;
    height: 330px;

  }

  .bgimgcontainer {
    height: 350px;
  }
}