.navbar {
  padding: 0px 20px 10px 20px;
}

.nav-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: inline-block;
}

.nav-menu-container {
  overflow-x: auto;
  white-space: nowrap;
}

.nav-item {
  margin-right: 40px;
  display: inline-block;
}

@media screen and (min-width: 1100px) {
  .nav-item {
    margin-right: 40px;
  }
}

.nav-link {
  color: #333;
  text-decoration: none;
}

.nav-link:hover {
  text-decoration: underline;
}

.nav-item.active .nav-link {
  text-decoration: underline;
}

.active {
  /* text-decoration: underline; */
  padding-top: 10px;
  border-bottom: 1px solid #E44190;
  font-weight: 600;
  color: #E44190
}

.sticky-navbar {
  position: sticky;
  top: 0;
  background-color: #fff;
  /* Set your desired background color */
  z-index: 1000;
  /* Adjust the z-index as needed */
  /* Add any other styles you want for the sticky navbar */
}

.work-hours {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 5;
  /* gap: 30px; */
  align-items: center;
}

.work-hours>p {
  width: 200px;
  color: #E44190;
  font-weight: 600;
  margin-bottom: 5px;
}

.open-hospital {
  color: green !important;
}

.timing {
  background: #d9eaf5;
  width: max-content;
  padding: 5px;
  border-radius: 4px;
  font-weight: 500;
}

.nav-menu-container::-webkit-scrollbar {
  display: none;
}

.nav-menu-container {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}