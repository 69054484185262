/* * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background: #000;
}

.slider {
    margin: 0 20px;
    overflow: "hidden";
    padding: 2rem 0;
}

.slider img {
    width: 100%;
    border-radius: 10px;
}

.react-multi-carousel-list {
    padding: 0rem 0 2rem 0;
}

.custom-dot-list-style button {
    border: none;
    background: rgb(255, 68, 68);
}

.react-multi-carousel-dot.react-multi-carousel-dot--active button {
    background: rgb(255, 68, 68) !important;
} */

.react-multi-carousel-track {
    margin-bottom: 30px !important;
}

@media screen and (max-width: 608px) {
    .leftarrowbtn {
        top: calc(100px - 220px);
        /* Adjust top position to move the arrow upside */
        position: absolute;
        cursor: pointer;
        color: black !important;
        /* Change arrow color */
        font-size: 200px;
        /* Adjust arrow size */
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .carouseldotsfeed {
        width: 6;
        height: 6;
        display: "inline-block";
        margin: "10px 0px";

        position: absolute;
        bottom: 50px !important;
        padding: 0px;
        text-align: center;
    }
}

.carouseldotsfeed {
    width: 6;
    height: 6;
    display: "inline-block";
    margin: "10px 0px";
    /* color: yellow; */
    position: absolute;
    bottom: 235px !important;
    padding: 0px;
    text-align: center;
    /* border: 1px solid red; */
}

.custom-dot {

    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #F5C1D9;
    /* Inactive dot color */
}

.slick-dots li.slick-active .custom-dot {
    background-color: #E44190;
    /* Active dot color */
}

.custom-dots-container {
    padding-top: 10px;
    /* Adjust the value as needed */
    border-radius: 10px;
}

/* .slider-container .slick-prev:before {
    font-size: 40px;
    margin-right: 10px;
} */