body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

body::-webkit-scrollbar {
  width: 0.5em;
  display: none;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px pink;
}

body::-webkit-scrollbar-thumb {
  background-color: pink;
  /* outline: 1px solid slategrey; */
}

@media screen and (min-width: 1536px) {
  body {
    max-width: 1535px;
    margin: 0 auto !important;
    display: block;
    border: 1px solid #d7d4d447;
  }
}

/* globals.css */
body {
  font-family: "Poppins", sans-serif;
}

.disablecontent {
  pointer-events: 'none';
  opacity: 0.7
}