.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.navbarbackground {
  background: rgba(228, 65, 144, 0.7);
}

@media screen and (max-width: 608px) {
  .navbarbackground {
    display: block;
    padding: 5px;
  }
}

@media screen and (max-width: 608px) {
  .topHeader {
    /* background: rgba(225, 255, 255, 0.88); */
    background: rgba(225, 255, 255, 1);
    /* background: #E4E2E1; */
    /* opacity: 1; */
    /* padding: 0.5rem 1rem; */
    /* padding: 15px 10px 18px 10px !important; */
    /* padding: 21px 10px 0px 10px !important; */
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .carouseldots {
    width: 6;
    height: 6;
    display: "inline-block";
    margin: "10px 0px";

    position: absolute;
    bottom: 50px !important;
    padding: 0px;
    text-align: center;
  }

  .fifthSlideImage {
    width: 1200px;
    height: 430px;
  }
}

.hospitalLocation {
  background: rgba(255, 255, 255, 0.4);
}

.topHeader {
  background: rgba(225, 255, 255, 0.78);
  /* background: #E4E2E1; */
  /* opacity: .78; */
  /* padding: 0.5rem 1rem; */
}

.secondheader {
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(255, 255, 255, 1);
  backdrop-filter: blur(20px);
  z-index: 10;
}

@media screen and (min-width: 1536px) {
  .secondheader {
    max-width: 1533px;
    margin: 0 auto !important;
    display: block;
  }
}

.carouselContainer {
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 51vh;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 50%;
  padding: 15px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media screen and (min-width: 1440px) {
  .carouselContainer {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 51vh;
    left: 30%;
    transform: translate(-50%, -50%);
    width: 40%;
    padding: 15px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

@media screen and (max-width: 608px) {
  .carouselContainer {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 20vh;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    /* padding: 15px; */
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
  }

  .firstSlideSubtext {
    font-size: 15px !important;
    color: white;
    margin-top: '10px';
    /* border:1px solid red */
  }

  .thirdslidecontainer {
    position: absolute;
    top: 18.5% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 82% !important;
    padding: 10px 10px !important;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px !important;
    background: rgba(255, 255, 255, 0.66);
    /* border: 1px solid red; */
  }

  .slidefirstheading {
    font-size: 16px !important;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0;
    /* line-height: 40px; */
    /* padding: 0px 20px; */
    font-weight: 600;
    margin-top: 0px !important;
    /* border: 1px solid red; */
  }
}

@media screen and (min-width: 1440px) {
  .thirdslidecontainer {
    background: rgba(255, 255, 255, 0.66);
    position: relative;
    top: 63% !important;
    left: 30% !important;
    transform: translate(-50%, -50%);
    width: 40% !important;
    padding: 15px 30px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* border: 1px solid red; */
  }

}

@media screen and (max-width: 608px) {
  .carouselContainer2 {
    position: absolute;
    padding: 15px 2px !important;
    top: 20% !important;
    height: 200px !important;
    font-size: 16px;


  }

  .fifthslidecontainer {
    /* background: rgba(0, 0, 0, 0.3); */
    position: absolute;
    /* top: 51vh; */
    top: 20% !important;
    height: 200px !important;
    left: 50% !important;
    width: 80% !important;
    padding: 5px !important;
    /* align-items: flex-start; */
    /* display: flex; */
    /* flex-direction: column; */
    /* gap: 20px; */
  }
}

@media screen and (max-width: 608px) {
  .carouselHead {
    height: 600px !important;
    position: absolute;
    top: 22.9% !important;
    min-width: 370px !important;
    /* border: 1px solid red !important; */
    /* background-color: red; */
  }

  .loginButton {
    border: none !important;

  }
}

@media screen and (max-width: 608px) {
  .registorbtn {
    display: flex;
    position: relative;
    top: 0.1vh;
    left: 25%;
  }

  .carouselHead4 {
    height: 600px !important;
    position: relative;
    top: 20% !important;
    /* border: 1px solid red; */

  }

  .consultbtn {
    display: flex;
    position: relative;
    left: 25%;
  }
}

@media screen and (max-width: 608px) {
  .appintmentbtn {
    display: "flex";
    position: relative;
    left: 22%;
    margin-top: -22px !important;
  }

  .firstslideContainer {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 16vh !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    width: 80% !important;
    text-align: left;
    height: 170px !important;
    padding: 10px !important;

    gap: 20px;
  }
}

@media screen and (max-width: 608px) {
  .schedulebtn {
    display: "flex";
    /* justify-content: "center"; */
    /* align-items: "center"; */
    /* border: 1px solid red; */
    /* color: "#e44190"; */
    /* left: 20%; */
    position: relative;
    /* top: 51vh; */
    left: 20%;
  }

  .schedulevaccationbtn {
    position: relative;
    left: 15% !important;

  }
}

@media screen and (max-width: 608px) {
  .leftarrow {
    top: calc(100px - 220px);
    /* Adjust top position to move the arrow upside */
    position: absolute;
    cursor: pointer;
    color: black !important;
    /* Change arrow color */
    font-size: 200px;
    /* Adjust arrow size */
  }

  .fifthSlideImage {
    width: 1200px;
    height: 240px;
    /* border: 1px solid red; */
  }

  .carouseldots {
    width: 6;
    height: 6;
    display: "inline-block";
    margin: "10px 0px";
    /* color: yellow; */
    position: absolute;
    bottom: 235px !important;
    padding: 0px;
    text-align: center;
    /* border: 1px solid red; */
  }
}

@media (max-width: 600px) {

  .NavbarMenuItems .text-white,
  .NavbarMenuItems .text-base,
  .NavbarMenuItems .text-sm {
    text-align: center;
  }
}

@media screen and (min-width:768px) and (max-width : 1024px) {
  .firstslideContainer {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 50vh;
    left: 30%;
    transform: translate(-50%, -50%);
    width: 46% !important;
    text-align: left;
    padding: 15px 15px;
  }

  .slidefirstheading {
    font-size: 20px !important;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0;
    font-weight: 600;
    margin-top: 0px !important;
    /* border: 1px solid red; */
  }

  .thirdslidecontainer {
    position: absolute;
    top: 58% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 82% !important;
    padding: 10px 10px !important;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px !important;
    background: rgba(255, 255, 255, 0.66);
    /* border: 1px solid red; */
  }

  .fourthslidecontainer {
    background: rgba(255, 255, 255, 0.66);
    position: absolute;
    top: 60% !important;
    left: 45% !important;
    transform: translate(-50%, -50%);
    width: 75% !important;
    padding: 25px;
    text-align: left;
    gap: 10px;
    /* border: 1px solid red; */
  }

  .slidefourthheading {
    font-size: 27px !important;
    color: #000000;
    letter-spacing: 0;
    line-height: 30px !important;
    font-weight: 600;

  }

  .slidesecondheading {
    font-size: 27px !important;
    color: white;
    text-transform: uppercase;
    text-align: left !important;
    /* width: 450px; */
    font-weight: bold;

  }

  .fifthslidecontainer {
    position: absolute;
    top: 60% !important;
    left: 70% !important;
    transform: translate(-50%, -50%);
    width: 55% !important;
    padding: 15px;
    /* align-items: flex-start;
  display: flex;
  flex-direction: column; */
    text-align: start;
    gap: 20px;
    /* border: 1px solid red; */
  }

  .slidefifthheading {
    font-size: 18px !important;
    color: #ffffff;
    letter-spacing: 0;
    /* line-height: 40px; */
    font-weight: 600;
    margin-top: "10px";
  }




}

.container {
  width: 100vw;
  background: #1d5775;
  padding-bottom: 201px;
}

.branchcontainer {
  width: 100vw;
  background: #ffffff;
  /* padding-bottom: 201px; */
}



@media screen and (min-width: 1536px) {
  .container {
    width: 1535px;
    margin: 0 auto !important;
    /* display: block; */
  }
}

@media screen and (min-width: 1536px) {
  .branchcontainer {
    width: 1535px;
    margin: 0 auto !important;
    /* display: block; */
  }
}

.navbarItem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  margin-top: 10px;
  cursor: pointer;
}

.portal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 5%;
}

.portalText {
  color: #fff !important;
  font-size: 1.3vw !important;
  margin-top: 10px;
  cursor: pointer;
}

.descriptionText {
  color: white;
}

.arrowIcon {
  color: #fff;
  font-size: 5vw;
}

.descriptiontext {
  color: #fff;
  font-size: 1vw;
  margin-top: 2px;
  font-weight: 400;
}

.popoverstyles {
  border-radius: 30%;
}

.subservicestext {
  color: #fff;
  font-size: 12px;
  padding-bottom: 5px;
  /* font-weight: 400; */
}

.servicesheading {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 10px;
  padding-top: 10px;
}

.subcontainer {
  position: absolute;
  top: 70%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 40%;
  padding: 15px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10;
}

/* styles.module.css */

.fixedHeader {
  position: fixed;
  top: 0;
  width: 100%;
  background: white;
}

.headerContent {
  padding: 0.5rem 1rem;
}

.backgroundContainer {
  background: #e44190;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.innerBackground {
  display: flex;
  justify-content: center;
}

.gridContainer {
  display: flex;
  justify-content: center;
}

.gridItem {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 15px 20px;
  cursor: pointer;
}

.icon {
  color: #fff;
  font-size: 1.5vw;
}

.titleText {
  color: #fff;
  font-size: 1vw;
  margin-top: 2px;
}

.loginButton {
  color: #1d5775;
  background-color: #ffffff;
  border: 0.2px solid #d2d4d5;
  /* opacity: 0.66; */
  /* margin-right: 30px; */
  text-transform: capitalize;
  border-radius: 8px;
  padding: 2px 10px;
}

.loginButton:hover {
  background-color: #fff !important;
}

.paperContainer {
  padding: 5px 23px;
  border-radius: 13px;
  z-index: 1;
  cursor: pointer;
}

.paperTypography {
  font-size: 14px;
}

.paperSubTypography {
  font-family: helvetica;
  font-weight: bold;
}

.popoverContainer {
  width: 100%;
}

.popoverCard {
  display: flex;
  width: 230px;
  align-items: center;
  gap: 5px;
  padding: 24px 5px 5px 5px;
  margin-top: -10px;
  z-index: 100;
  cursor: pointer;
}

.popoverTitle {
  font-family: helvetica;
  font-size: 14px !important;
  font-weight: bold;
}

.popoverSubtitle {
  font-family: helvetica;
  font-size: 12px !important;
  color: #707070;
  white-space: nowrap;
}

.popoverArrow {
  color: #0007b7;
  font-size: 24px;
}

.firstslideContainer {
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 50vh;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 36%;
  text-align: left;
  padding: 15px 15px;
}



.secondslidecontainer {
  position: relative;
  top: 60%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 40%;
  padding: 15;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.thirdslidecontainer {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  padding: 15px 30px;

  text-align: center;
  background: rgba(255, 255, 255, 0.66);
  /* border: 1px solid red; */
}

.fourthslidecontainer {
  background: rgba(255, 255, 255, 0.66);
  position: absolute;
  top: 51vh;
  left: 25%;
  transform: translate(-50%, -50%);
  width: 35%;
  padding: 25px;
  text-align: left;
  gap: 20px;
}

.fifthslidecontainer {
  position: absolute;
  top: 63%;
  left: 70%;
  transform: translate(-50%, -50%);
  width: 40%;
  padding: 15px;
  /* align-items: flex-start;
  display: flex;
  flex-direction: column; */
  text-align: start;
  gap: 20px;
}

@media screen and (max-width: 608px) {
  .fourthslidecontainer {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 19.3vh !important;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    padding: 13px 15px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* border: 1px solid red; */
  }

  .slidefourthheading {
    font-size: 16px !important;
    color: #000000;
    letter-spacing: 0;
    line-height: 30px !important;
    font-weight: 600;

  }

  .slidefourthheading1 {
    font-size: 14px !important;
    margin-top: -5px !important;
  }

  .slidefifthheading1 {
    font-size: 14px !important;
    margin-top: 10px !important;
  }

  .portalText {
    color: #fff !important;
    font-size: 18px !important;
    margin-top: 10px;
    cursor: pointer;
  }




  .slidesecondheading {
    font-size: 16px !important;
    color: white;
    text-transform: uppercase;
    text-align: left !important;
    /* width: 450px; */
    font-weight: bold;

  }

  .slidesecondheading2 {
    font-size: 14px !important;
    color: white;
    text-transform: uppercase;



  }

  .slidethirdheading {
    font-size: 15px !important;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0;
    font-weight: bold;
    line-height: 30px !important;
  }

  .childinformation {
    display: flex;
    flex-direction: row !important;
    margin-top: -16px !important;
  }

  .item {
    /* flex: 1;
  text-align: center;
  border-right: none !important;
  padding: 0px;
  font-weight: 500;
  font-size: 14px !important; */
    display: flex !important;
    flex-direction: row !important;
    border-right: none !important;
    font-weight: 500;
    font-size: 14px !important;
  }

  .slidefifthheading {
    font-size: 16px !important;
    color: #ffffff;
    letter-spacing: 0;
    /* line-height: 40px; */
    font-weight: 600;
    margin-top: "10px";
  }


  .loginbuttoncontainer {
    display: flex;
    justify-content: center;
    margin-top: 10px !important;
    gap: 10px;
    padding-left: 40px;
    /* justify-content: flex-start !important; */
    /* align-items: start; */
    /* border: 1px solid red; */
  }


  .popoverArrow {
    color: #0007b7;
    font-size: 24px;
    position: relative;
    left: -65px;
  }
}

.loginbuttoncontainer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  gap: 10px;
  align-items: center;
}

.patientportaltext {
  color: #fff;
  font-size: 20px;
  margin-top: 2;
}

.logintext {
  color: #fff;

}

.loginbuttontext {
  color: #fff;
  font-size: 1vw;
  margin-top: 2;
  font-weight: 400;
}

.righttobracketicon {
  color: #fff;
  font-size: 3vw;
}

.serviesmenugridcontainer {
  display: flex;
  gap: 20px;
  flex-direction: column;
  text-align: left;
  padding-left: 33px;
}

.slidecontainerheading {
  font-size: 18px;
  color: white;
  text-transform: uppercase;
  text-align: left;
}

.slidefirstheading {
  font-size: 37px;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0;
  /* line-height: 40px; */
  /* padding: 0px 20px; */
  font-weight: 600;
  margin-top: 10px;
}

.slidesecondheading {
  font-size: 37px;
  color: white;
  text-transform: uppercase;
  text-align: left;
  width: 450px;
}

.slidethirdheading {
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
}

.slidefourthheading {
  font-size: 40px;
  color: #000000;
  letter-spacing: 0;
  line-height: 40px;
  font-weight: 600;
}

.slidefifthheading {
  font-size: 37px;
  color: #ffffff;
  letter-spacing: 0;
  /* line-height: 40px; */
  font-weight: 600;
  margin-top: "10px";
}

.childinformation {
  display: flex;
  margin-top: 7px;
}

.item {
  flex: 1;
  text-align: center;
  border-right: 2px solid black;
  padding: 0px;
  font-weight: 500;
  font-size: 20px;
}

.item:last-child {
  border-right: none;
  /* Remove border from the last item */
}